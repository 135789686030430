import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pause Bench Press 4×2\\@85% 1RM`}</p>
    <p>{`Strict Pullups 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`Death by Clapping Pushups x2`}</p>
    <p>{`2 Clapping Pushups minute 1:00`}</p>
    <p>{`4 Clapping Pushups minute 2:00`}</p>
    <p>{`6 Clapping Pushups minute 3:00, etc until failure`}</p>
    <p>{`rest 2:00 then,`}</p>
    <p>{`Death by Bodyweight Rows x2 (Lying under racked barbell at hip height)`}</p>
    <p>{`2 Bodyweight Rows minute 1:00`}</p>
    <p>{`4 Bodyweight Rows minute 2:00`}</p>
    <p>{`6 Bodyweight Rows minute 3:00, etc until failure`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be meeting at Perrin Park in Jeffersonville, IN today at
5:30pm for an outdoor workout!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      